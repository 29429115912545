<template>
  <div
    v-if="topBanner"
    class="p-3 flex min-h-[44px] w-full items-center justify-center bg-pink-light text-center text-xs font-medium uppercase text-grey-dark-01"
  >
    {{ topBanner.fieldTopBanner }}
  </div>
</template>

<script lang="ts" setup>
// Access the topBannerPromotions from the navbar data
const { data } = await useInitData()

// Get the top banner promotion directly in the component
const topBanner = computed(() => {
  // Get the topBannerPromotions from the navbar data
  const promotions = data.value.topBannerPromotions

  if (promotions && promotions.length > 0) {
    return promotions[0]
  }
  return null
})
</script>
