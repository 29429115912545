<template>
  <nav
    ref="navbar"
    class="h-116 lg:h-116 sticky top-0 z-navbar w-full bg-white transition ease-swing after:absolute after:bottom-0 after:left-0 after:z-50 after:w-full after:border-b after:border-b-grey-light-01 after:content-['']"
  >
    <AppTopTopBanner />
    <AppTopNavbar
      v-model:menu-is-open-on-desktop="menuIsOpenOnDesktop"
      v-model:menu-is-open-on-mobile="menuIsOpenOnMobile"
      :always-closed="isEditing"
    />
  </nav>
</template>

<script lang="ts" setup>
import AppTopTopBanner from './TopBanner/index.vue'

defineProps<{
  isEditing?: boolean
}>()

const menuIsOpenOnDesktop = ref<boolean>()
const menuIsOpenOnMobile = ref<boolean>()
const menuIsOpen = computed(
  () => menuIsOpenOnMobile.value || menuIsOpenOnDesktop.value,
)

const { userCanHover } = useUserCanHover()

// hide and show the navbar on scorlling.
// smooth by 100px so you do not get a flickering appearing navbar
const navbar = ref<HTMLElement>()
const scrollingDownStartY = ref<number>()
const scrollDownStartTime = ref(0)
const scrollingUpStartY = ref<number>()
const hoverScrollStartY = ref(0)
const prevScrollY = ref(0)

watch(menuIsOpen, () => {
  if (menuIsOpen.value) {
    if (userCanHover.value) {
      // disable scrolling up if hovering open menu.
      window.scrollTo(0, hoverScrollStartY.value)
    }

    // make sure we show the whole navbar
    if (navbar.value) {
      navbar.value.style.top = '0'
    }
  }
})

if (process.browser) {
  window.addEventListener('scroll', () => {
    if (!navbar.value) {
      return
    }

    const mouseHovers = document.querySelectorAll(':hover')
    const mouseHoversLast = mouseHovers.length
      ? mouseHovers[mouseHovers.length - 1]
      : undefined
    const hoveringOverNavbar = mouseHoversLast
      ? navbar.value.contains(mouseHoversLast)
      : false

    const currentScrollY = window.scrollY

    // check if user can hover, as on touch devices mouseHovers returns wrong array.
    if (userCanHover.value && hoveringOverNavbar) {
      if (!menuIsOpenOnMobile.value) {
        if (currentScrollY > hoverScrollStartY.value) {
          navbar.value.style.transition = 'top 0s'
          navbar.value.style.top = `-${currentScrollY - hoverScrollStartY.value}px`
        } else {
          hoverScrollStartY.value = currentScrollY
        }
      }

      return
    } else {
      hoverScrollStartY.value = currentScrollY
      navbar.value.style.removeProperty('transition')
    }

    if (prevScrollY.value > currentScrollY) {
      if (scrollingUpStartY.value === undefined) {
        scrollingUpStartY.value = currentScrollY
      }

      if (scrollingUpStartY.value - 100 > currentScrollY) {
        navbar.value.style.top = '0'
        scrollingDownStartY.value = undefined
      }
    }

    if (prevScrollY.value < currentScrollY) {
      if (scrollingDownStartY.value === undefined) {
        scrollingDownStartY.value = currentScrollY
        scrollDownStartTime.value = Date.now()
      }

      if (
        (scrollingDownStartY.value + 100 < currentScrollY &&
          // Delay hide of navbar. Otherwise it hides too fast on fast scrolling devices.
          scrollDownStartTime.value < Date.now() - 100) ||
        // reading always returns '0px', even if it was set to '0'
        navbar.value.style.top !== '0px' // if we are scrolling away the navbar. Don't delay.
      ) {
        navbar.value.style.top = `-${navbar.value.clientHeight}px`
        scrollingUpStartY.value = undefined
      }
    }

    prevScrollY.value = currentScrollY
  })
}
</script>

<style lang="scss" scoped>
nav {
  transition: top 0.3s;
}
</style>
